import { LimitOrderProtocolFacade, Web3ProviderConnector } from '@1inch/limit-order-protocol'
import { useWeb3React } from '@telekomconsalting/core'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getLimitOrdersData } from '../../actions/limitOrderActions'
import { AmplitudeEvent } from '../../constants/amplitudeEvents'
import { getRootElement } from '../../helpers/appHelpers'
import { getDateFromTimestamp } from '../../helpers/datetime'
import { financialFormatLowValueHighPrecision } from '../../helpers/priceAndAmountHelpers'
import { Address, HttpError, TokenV3, TradeBarData, TransactionId } from '../../model'
import { LimitOrder } from '../../model/limitOrderModel'
import { State } from '../../reducers'
import amplitudeService from '../../services/amplitudeService'
import { getIdFromTokenAddress, getMarketDisplayName, getNetworkConfigByChainId } from '../../utils'
import { ButtonProps } from '../buttons/Button'
import CloseButton from '../buttons/CloseButton'
import TransactionOwn from '../Transactions/TransactionOwn'
import { getExpireTimestampFromOrder, getOrderStatus, getRPCErrorText } from './helpers'
import LimitOrderCancelModal from './LimitOrderCancelModal'

interface LimitOrderRowProps {
  limitOrder: LimitOrder
  makerToken: TokenV3
  takerToken: TokenV3
  option?: number
  tradeBarData?: TradeBarData
  onClickRow?: (wallet?: Address, txnId?: TransactionId) => void
  account?: Address | null
}

const LimitOrderRow: FC<LimitOrderRowProps> = (props) => {
  const dispatch = useDispatch()

  const { account, library, chainId } = useWeb3React()
  const [isLoading, setLoading] = useState(false)
  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const [isCancelLimitOrderModalOpen, setIsCancelLimitOrderModalOpen] = useState<boolean>(false)
  const [actionError, setActionError] = useState<string | null>(null)

  const tokenNetwork = currentToken?.network
  const networkConfig = getNetworkConfigByChainId(chainId)

  const fromAddress = props.limitOrder.details.data.makerAsset
  const toAddress = props.limitOrder.details.data.takerAsset
  if (!currentToken || !tokenNetwork || !fromAddress || !toAddress || !networkConfig) {
    return null
  }

  const amountFrom = new BigNumber(props.limitOrder.details.data.makingAmount).div(
    10 ** props.makerToken.decimals
  )
  const amountTo = new BigNumber(props.limitOrder.details.data.takingAmount).div(
    10 ** props.takerToken.decimals
  )

  const deltaClassName = currentToken?.id === props.makerToken.address ? 'down' : 'up'

  const commonProps = {
    className: classNames(
      'history-content__trow',
      'history-content__trow--active',
      `history-content__trow--${deltaClassName}`,
      'history-content__trow--own'
    ),
    'data-testid': `table-row-${deltaClassName}`,
  }
  const handleCancelOrder = (): void => {
    setLoading(true)
    setActionError(null)

    const connector = new Web3ProviderConnector(library)
    const oneInchAddress = networkConfig.one_inch_api.spender_address
    if (!oneInchAddress) {
      console.error('oneInchAddress was not found')
      return
    }

    const limitOrderProtocolFacade = new LimitOrderProtocolFacade(oneInchAddress, connector)

    const callData = limitOrderProtocolFacade.cancelLimitOrder(props.limitOrder.details.data)

    library.eth
      .sendTransaction({
        from: account,
        to: oneInchAddress,
        data: callData,
      })
      .once('confirmation', () => {
        if (account && currentToken?.id) {
          dispatch(getLimitOrdersData(account, currentToken.id))
        }

        amplitudeService.sendEvent(AmplitudeEvent.LIMIT_ORDER_CANCELED, {
          token_id: getIdFromTokenAddress(fromAddress, tokenNetwork),
          token_pair: getIdFromTokenAddress(toAddress, tokenNetwork),
          network: tokenNetwork,
        })

        setLoading(false)
      })
      .catch((error: HttpError) => {
        console.error('Error during canceling limit order', error)

        const errorDisplay = getRPCErrorText(error?.message)

        setActionError(errorDisplay)
        setLoading(false)
      })
  }

  const handleCloseModal = (): void => {
    setIsCancelLimitOrderModalOpen(false)
  }

  const modalActions: ButtonProps[] = [
    {
      caption: !isLoading ? 'Cancel Order' : '',
      disabled: isLoading,
      pending: isLoading,
      buttonSize: 'md',
      className: 'button--accept',
      onClick: handleCancelOrder,
    },
    {
      caption: `Don't Cancel`,
      disabled: isLoading,
      buttonSize: 'md',
      className: 'button--decline',
      onClick: handleCloseModal,
    },
  ]

  const timestamp = getExpireTimestampFromOrder(props.limitOrder.details.data.predicate)
  let timeContent = <span className="expiration__value"></span>

  if (timestamp) {
    const rowDate = getDateFromTimestamp(timestamp, { month: 'short', day: '2-digit' })
    const rowTime = getDateFromTimestamp(timestamp, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
    const tooltipDate = getDateFromTimestamp(timestamp, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })

    timeContent = (
      <span className="expiration__value" data-tip={tooltipDate} data-for="app-tooltip">
        <span className="date">
          {rowDate}
          <span className="delimiter">,</span>
        </span>{' '}
        <span className="time">{rowTime}</span>
      </span>
    )
  }

  const renderData = (
    <>
      <div className="type" />
      <div className="own">
        <TransactionOwn />
      </div>
      <div className="amount">
        <div className="amount__value">
          <div className="value" title={financialFormatLowValueHighPrecision(amountFrom)}>
            {financialFormatLowValueHighPrecision(amountFrom)}
          </div>
          <div className="value" title={financialFormatLowValueHighPrecision(amountTo)}>
            {financialFormatLowValueHighPrecision(amountTo)}
          </div>
        </div>
      </div>
      <div className="token">
        <div className="token__symbol">
          <div className="symbol" title={getMarketDisplayName(props.makerToken)}>
            {getMarketDisplayName(props.makerToken)}
          </div>
          <div className="symbol" title={getMarketDisplayName(props.takerToken)}>
            {getMarketDisplayName(props.takerToken)}
          </div>
        </div>
      </div>
      <div className="expiration">{timeContent}</div>
      <div className="status">
        <div className="tag">
          {getOrderStatus(props.limitOrder.details, props.limitOrder.events)}
        </div>
      </div>
      <div className="action">
        <CloseButton
          size="sm"
          onClick={(): void => setIsCancelLimitOrderModalOpen(true)}
          tooltip="Cancel limit order"
        />
      </div>
      <LimitOrderCancelModal
        parentSelector={getRootElement}
        isOpen={isCancelLimitOrderModalOpen}
        onClose={handleCloseModal}
        onRequestClose={handleCloseModal}
        overlayClassName="modal-overlay"
        className="modal-content paid-subscriptions-container"
        title="Cancel limit order"
        error={actionError}
        modalActions={modalActions}>
        You will be charged a gas fee for manually cancelling your limit order before it expires.
        Are you sure you want to cancel it?
      </LimitOrderCancelModal>
    </>
  )

  return (
    <div {...commonProps} key={props.limitOrder.details.orderHash}>
      {renderData}
    </div>
  )
}

export default LimitOrderRow
