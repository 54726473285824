import { useWeb3React } from '@telekomconsalting/core'
import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getLimitOrdersData } from '../../actions/limitOrderActions'
import { refreshTokens } from '../../actions/tokenActions'
import { LimitOrder } from '../../model/limitOrderModel'
import { State } from '../../reducers'
import { getTokenIdsFromLimitOrderList } from '../../services/limitOrdersService'
import { getMissingTokenIds } from '../../services/tokenService'
import { getMarketDisplayName } from '../../utils'
import { Heading } from '../Heading'
import Message, { MessageIcons } from '../Message'
import { TradingHistoryMessages } from '../TransactionHistory/TradingHistory.enums'
import LimitOrderRow from './LimitOrderRow'
import LimitOrdersHeader from './LimitOrdersHeader'

const LimitOrders: FC = () => {
  const dispatch = useDispatch()

  const { account } = useWeb3React()
  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const tokens = useSelector((state: State) => state.tokens.tokens)
  const { isLoading, limitOrders } = useSelector((state: State) => state.limitOrders)

  const getData = useCallback((): void => {
    if (account && currentToken?.id) {
      dispatch(getLimitOrdersData(account, currentToken.id))
    }
  }, [account, currentToken?.id, dispatch])

  useEffect(() => {
    getData()
  }, [account, currentToken?.id, getData])

  // retrieve ids of tokens from orderbook for the user. to fetch the tokens and get their decimals and symbols
  useEffect(() => {
    if (!limitOrders || !currentToken?.id) {
      return
    }
    const tokenIds = getTokenIdsFromLimitOrderList(limitOrders, currentToken.id)
    const missingTokenIds = getMissingTokenIds(tokenIds, tokens)

    missingTokenIds.length > 0 && dispatch(refreshTokens(missingTokenIds))
  }, [limitOrders, tokens, currentToken?.id, dispatch])

  const noDataMessage = isLoading
    ? TradingHistoryMessages.loading
    : `No active limit orders with ${getMarketDisplayName(
        currentToken
      )}. Try choosing a different token inside Market Selector`
  if (!currentToken) {
    return null
  }

  return (
    <div className="limit-orders">
      <div className="history-content history-content--compact">
        <div className="history-content__header">
          <Heading size="xs">My Limit Orders</Heading>
        </div>
        <div className="history-content__body">
          {isLoading && (
            <div className="history-content__nodata">
              <div className="text">{TradingHistoryMessages.loading}</div>
            </div>
          )}

          {!isLoading && !limitOrders?.length && (
            <Message
              text={noDataMessage}
              type="empty"
              icon={MessageIcons.warning}
              isShowMascot={false}
            />
          )}

          {!isLoading && !!limitOrders?.length && (
            <div className="history-content__table history-content__table--compact">
              <LimitOrdersHeader />

              <div className="history-content__tbody">
                {!!limitOrders?.length &&
                  limitOrders.map((limitOrder: LimitOrder) => {
                    const makerToken = tokens.find(
                      (x) =>
                        x.id ===
                        `${limitOrder.details.data.makerAsset.toLowerCase()}-${
                          currentToken.network
                        }`
                    )
                    const takerToken = tokens.find(
                      (x) =>
                        x.id ===
                        `${limitOrder.details.data.takerAsset.toLowerCase()}-${
                          currentToken.network
                        }`
                    )
                    if (!makerToken || !takerToken) {
                      return null
                    }
                    return (
                      <LimitOrderRow
                        key={limitOrder.details.signature}
                        limitOrder={limitOrder}
                        takerToken={takerToken}
                        makerToken={makerToken}
                      />
                    )
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LimitOrders
