import React, { FC } from 'react'

const LimitOrdersHeader: FC = () => {
  return (
    <div className="history-content__thead">
      <div className="history-content__trow">
        <div className="type" />
        <div className="own" />
        <div className="amount">
          <span className="caption">Pay/Receive</span>
        </div>
        <div className="token" />
        <div className="expiration">
          <span className="caption">Expiration</span>
        </div>
        <div className="status">
          <span className="caption">Status</span>
        </div>
        <div className="action"></div>
      </div>
    </div>
  )
}
export default LimitOrdersHeader
